import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../layouts/index';
import Content_main from '../../components/Content_main';
import Img from 'gatsby-image';

const Unternehmen = (props) => {
  const category = props.data.allSites.edges;
  const category_subs = props.data.subSites.edges;
  const sum = {category, category_subs}
  return (
    <Layout bodyClass="page-das-unternehmen">
      

      <Content_main content_object={sum} />
      
    </Layout>
  );
};

export const query = graphql`
  query UnternehmenQuery {
    allSites: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/das-unternehmen/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            category_content
            featuredImage{
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            category
            description
          }
          html
        }
      }
    }
    subSites: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/das-unternehmen/" } , frontmatter: {category_content: {ne: true}}}
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            featuredImage{
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Unternehmen;
